import { memo, useEffect, useMemo, useState, type MouseEvent } from 'react'

import {
    Box,
    Dialog,
    Divider,
    List,
    ListItemText,
    DialogActions,
    ListItemButton
} from '@mui/material'

import CheckIcon from '@mui/icons-material/Check'

import {
    useLangaugeSpeakers,
    LanguageSpeakers
} from 'UI/Routes/quick-guidde/LeftPanel/VoiceOverPanel/speakers'
import { useSpeakerOptions } from 'UI/Routes/quick-guidde/hooks'

import { type SpeakerType } from 'app/types'
import { type OptionType } from 'modules'
import { ThemeProvider, Button, Typography } from '@guidde/design-system'

type Props = {
    isOpen: boolean
    activeLanguage: OptionType
    onClose: () => void
    onTranslate: (speaker: SpeakerType) => void
}

export const LanguageItem = memo(
    ({
        lang,
        selectedLang,
        onSelect
    }: {
        lang: OptionType
        selectedLang: OptionType
        onSelect: (lang: OptionType) => void
    }) => {
        const handleChangeLanguage = () => {
            onSelect(lang)
        }

        const isSelected = lang.value === selectedLang.value

        return (
            <ListItemButton
                selected={isSelected}
                onClick={handleChangeLanguage}
                key={lang.value}
                divider
            >
                <ListItemText>
                    <Typography variant="text" size="sm" fontWeight="medium">
                        {lang.label}
                    </Typography>
                </ListItemText>
            </ListItemButton>
        )
    }
)

export const LanguageList = memo(
    ({
        languages,
        selectedLang,
        onSelect
    }: {
        languages: OptionType[]
        selectedLang: OptionType
        onSelect: (lang: OptionType) => void
    }) => {
        return (
            <>
                {languages.map(lang => (
                    <LanguageItem
                        key={lang.value}
                        lang={lang}
                        selectedLang={selectedLang}
                        onSelect={onSelect}
                    />
                ))}
            </>
        )
    }
)

export const SpeakersDialog = memo(({ isOpen, onClose, onTranslate, activeLanguage }: Props) => {
    const { languagesList, hasStudioVoices, getSpeakersList, getInitialSpeaker, isLoading } =
        useSpeakerOptions()

    const [selectedLang, setSelectedLang] = useState(
        languagesList.filter(it => it.value !== activeLanguage.value)[0]
    )

    const speakers = useMemo(() => {
        return getSpeakersList(selectedLang.value)
    }, [getSpeakersList, selectedLang.value])

    const { defaultSpeakerId, brandkitSpeakerId, recentlyUsedSpeakers, restSpeakers } =
        useLangaugeSpeakers(speakers, selectedLang.value)

    const [selectedSpeaker, setSelectedSpeaker] = useState<SpeakerType | null>(null)

    useEffect(() => {
        if (!isLoading) {
            setSelectedSpeaker(getInitialSpeaker(selectedLang.value))
        }
    }, [isLoading, selectedLang, getInitialSpeaker])

    const handleTranslate = () => {
        if (!selectedSpeaker) return

        onTranslate(selectedSpeaker)
    }

    const handleSelectSpeaker = (_: MouseEvent<HTMLElement>, speaker: SpeakerType) =>
        setSelectedSpeaker(speaker)

    // Required function but there is no functionality for it
    const handleAudioPlay = () => {}

    const handleLanguageSelect = (lang: OptionType) => {
        setSelectedLang(lang)
    }

    return (
        <ThemeProvider>
            <Dialog
                onClose={onClose}
                open={isOpen}
                fullWidth
                maxWidth="md"
                PaperProps={{
                    style: {
                        maxWidth: 820
                    }
                }}
            >
                <Box pb={3} pt={2.5} px={4}>
                    <Typography variant="text" size="lg" fontWeight="semibold">
                        Select a language
                    </Typography>
                </Box>
                <Divider />
                <Box display="flex" pl={3} maxHeight={500}>
                    <List
                        style={{
                            minWidth: 225,
                            overflow: 'auto'
                        }}
                    >
                        <LanguageList
                            languages={languagesList.filter(
                                lang => lang.value !== activeLanguage.value
                            )}
                            selectedLang={selectedLang}
                            onSelect={handleLanguageSelect}
                        />
                    </List>

                    <Divider orientation="vertical" flexItem />
                    <Box overflow="auto" p={2} width="100%">
                        <LanguageSpeakers
                            onAudioPlay={handleAudioPlay}
                            onSelect={handleSelectSpeaker}
                            currentSpeaker={selectedSpeaker ?? null}
                            hasStudioVoices={hasStudioVoices}
                            brandkitSpeakerId={brandkitSpeakerId}
                            defaultSpeakerId={defaultSpeakerId}
                            recentlyUsedSpeakers={recentlyUsedSpeakers}
                            restSpeakers={restSpeakers}
                            gridGap="10px 6px"
                        />
                    </Box>
                </Box>
                <Divider />
                <DialogActions>
                    <Box p={1}>
                        <Button
                            variant="contained"
                            startIcon={<CheckIcon fontSize="small" />}
                            disabled={!selectedSpeaker}
                            onClick={handleTranslate}
                        >
                            Translate
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    )
})
