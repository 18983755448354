import { useCallback, useEffect } from 'react'

import { Button, Icon } from '@guidde/design-system'

import { faImage } from '@fortawesome/pro-regular-svg-icons/faImage'

import { logToAnalytics, uuid } from 'modules'
import { useBoolean } from 'hooks'

import {
    TimelineChunkVariant,
    type TimelineChunksType,
    type TimelineSnapshotChunkType,
    type TimelineVideoChunkType
} from '../types'

const defaultSnapshotChunkDuration = 2

const makeSnapshot = async (videoPlayerElement: HTMLVideoElement) => {
    return new Promise<{ blobUrl: string; width: number; height: number } | null>(
        (resolve, reject) => {
            const width = videoPlayerElement.videoWidth
            const height = videoPlayerElement.videoHeight

            const snapshotCanvas = document.createElement('canvas')

            snapshotCanvas.width = width
            snapshotCanvas.height = height

            const ctx = snapshotCanvas.getContext('2d')
            ctx?.drawImage(videoPlayerElement, 0, 0, width, height)

            snapshotCanvas.toBlob(async blob => {
                if (!blob) return reject(null)
                resolve({ blobUrl: URL.createObjectURL(blob), width, height })
            })
        }
    )
}

type Props = {
    chunks: TimelineChunksType
    setChunks: (value: TimelineChunksType) => void
    isDisabled: boolean
    videoPlayerElement: HTMLVideoElement | null
    getChunkSplitResult: () => {
        resultingChunks: Array<TimelineVideoChunkType>
        activeChunkIndex: number
    } | null
}

export const SnapshotButton = ({
    isDisabled,
    videoPlayerElement,
    chunks,
    setChunks,
    getChunkSplitResult
}: Props) => {
    const videoSeeking = useBoolean()
    const startVideoSeeking = videoSeeking.setTrue
    const endVideoSeeking = videoSeeking.setFalse

    const addSnapshot = useCallback(async () => {
        if (!videoPlayerElement) return

        const splitResult = getChunkSplitResult()
        if (!splitResult) return

        const { resultingChunks, activeChunkIndex } = splitResult

        const snapshotInfo = await makeSnapshot(videoPlayerElement)
        if (!snapshotInfo) return

        const snapshotChunk: TimelineSnapshotChunkType = {
            duration: defaultSnapshotChunkDuration,
            type: TimelineChunkVariant.snapshot,
            imageUrl: snapshotInfo.blobUrl,
            width: snapshotInfo.width,
            height: snapshotInfo.height,
            id: uuid()
        }

        const newChunks = [
            ...chunks.slice(0, activeChunkIndex),
            resultingChunks[0],
            snapshotChunk,
            resultingChunks[1],
            ...chunks.slice(activeChunkIndex + 1, chunks.length)
        ]

        setChunks(newChunks)
        logToAnalytics('editVideoStep_snapshotBtn_clicked', {
            snapshotTimeStamp: videoPlayerElement.currentTime
        })
    }, [chunks, getChunkSplitResult, setChunks, videoPlayerElement])

    useEffect(() => {
        if (!videoPlayerElement) return

        videoPlayerElement.addEventListener('seeking', startVideoSeeking)
        videoPlayerElement.addEventListener('seeked', endVideoSeeking)

        return () => {
            videoPlayerElement.removeEventListener('seeking', startVideoSeeking)
            videoPlayerElement.removeEventListener('seeked', endVideoSeeking)
        }
    }, [endVideoSeeking, startVideoSeeking, videoPlayerElement])

    return (
        <Button
            variant="text"
            color="secondary"
            size="medium"
            startIcon={<Icon icon={faImage} />}
            disabled={isDisabled || videoSeeking.isTrue}
            onClick={addSnapshot}
        >
            Snapshot
        </Button>
    )
}
