import { useCallback } from 'react'

import { Stack } from '@mui/material'

import { useNotification } from 'hooks'
import { uuid } from 'modules'

import {
    TimelineChunkVariant,
    type TimelineChunksType,
    type TimelineVideoChunkType
} from '../types'
import { SplitButton } from './SplitButton'
import { SnapshotButton } from './SnapshotButton'
import { minChunkDuration } from '../Timeline'
import { checkIsSnapshotChunk } from '../splitAndTrimUtils'
import { useMapTimelineToVideoTime } from '../useMapTimelineToVideoTime'

type Props = {
    pointerTime: number
    chunks: TimelineChunksType
    setChunks: (value: TimelineChunksType) => void
    videoPlayerElement: HTMLVideoElement | null
}

export const PointerButtons = ({ pointerTime, chunks, setChunks, videoPlayerElement }: Props) => {
    const { showErrorNotification } = useNotification()

    const { activePointerChunkDetails: activeChunkDetails, pointerVideoTime } =
        useMapTimelineToVideoTime({
            pointerTime,
            chunks
        })

    const getChunkSplitResult = useCallback(() => {
        const activeChunk = activeChunkDetails?.originalChunk

        if (!activeChunk || checkIsSnapshotChunk(activeChunk) || !pointerVideoTime) return null

        const resultingChunks: [TimelineVideoChunkType, TimelineVideoChunkType] = [
            {
                id: uuid(),
                start: activeChunk.start,
                end: pointerVideoTime,
                type: TimelineChunkVariant.video
            },
            {
                id: uuid(),
                start: pointerVideoTime,
                end: activeChunk.end,
                type: TimelineChunkVariant.video
            }
        ]
        const isLessThanOneSecond = resultingChunks.some(
            chunk => chunk.end - chunk.start < minChunkDuration
        )

        if (isLessThanOneSecond) {
            showErrorNotification(`Video step duration can’t be under ${minChunkDuration} second`)
            return null
        }

        const activeChunkIndex = chunks.findIndex(chunk => chunk.id === activeChunk.id)

        return {
            resultingChunks,
            activeChunkIndex
        }
    }, [activeChunkDetails, chunks, pointerVideoTime, showErrorNotification])

    const areButtonsDisabled = activeChunkDetails?.originalChunk
        ? checkIsSnapshotChunk(activeChunkDetails.originalChunk)
        : true

    return (
        <Stack
            direction="row"
            spacing={3}
            alignItems="center"
            sx={theme => ({
                /* TODO: DS Button color */
                '& button': {
                    color: theme.palette.grey[800]
                }
            })}
        >
            <SplitButton
                chunks={chunks}
                setChunks={setChunks}
                isDisabled={areButtonsDisabled}
                getChunkSplitResult={getChunkSplitResult}
            />
            <SnapshotButton
                chunks={chunks}
                setChunks={setChunks}
                isDisabled={areButtonsDisabled}
                videoPlayerElement={videoPlayerElement}
                getChunkSplitResult={getChunkSplitResult}
            />
        </Stack>
    )
}
