import { useCallback, type MouseEvent, Fragment } from 'react'

import { Stack } from '@mui/material'

import { TimelineChunk } from './TimelineChunk'
import { GAP_WIDTH, TimelineConnector } from './TimelineConnector'

import { type TimelineChunksType } from '../types'
import { useVideoThumbnailCanvas } from './useVideoThumbnailCanvas'

type Props = {
    chunks: TimelineChunksType
    setChunks: (value: TimelineChunksType) => void
    endPoint: number
    thumbnailUrl: string
    timelineWidth: number
    duration: number
    timelineContainerElement: HTMLDivElement | null
    onTimeChange: (_seconds: number) => void
}

export const TimelineChunks = ({
    chunks,
    endPoint,
    thumbnailUrl,
    timelineWidth,
    duration,
    setChunks,
    timelineContainerElement,
    onTimeChange
}: Props) => {
    const { videoThumbnailCanvas, videoThumbnailCanvasWidth } = useVideoThumbnailCanvas({
        thumbnailUrl,
        timelineWidth,
        duration,
        endPoint
    })

    const handleTimelineClick = useCallback(
        (event: MouseEvent) => {
            if (!timelineContainerElement) return

            const rect = timelineContainerElement.getBoundingClientRect()
            const clickX = event.clientX - rect.left
            const clickPercentage = clickX / rect.width
            const newTime = Math.min(clickPercentage * endPoint, duration)

            onTimeChange(newTime)
        },
        [timelineContainerElement, endPoint, duration, onTimeChange]
    )

    return (
        <Stack direction="row" onClick={handleTimelineClick} sx={{ cursor: 'pointer' }} spacing={0}>
            {chunks.map((chunk, index) => {
                const canBeMerged = index !== chunks.length - 1

                return (
                    <Fragment key={chunk.id}>
                        <TimelineChunk
                            endPoint={endPoint}
                            videoThumbnailCanvas={videoThumbnailCanvas}
                            videoThumbnailCanvasWidth={videoThumbnailCanvasWidth}
                            duration={duration}
                            chunkIndex={index}
                            chunks={chunks}
                            setChunks={setChunks}
                            timelineContainerElement={timelineContainerElement}
                            widthDelta={canBeMerged ? -GAP_WIDTH : 0}
                        />
                        {canBeMerged && (
                            <TimelineConnector
                                chunkIndex={index}
                                chunks={chunks}
                                setChunks={setChunks}
                            />
                        )}
                    </Fragment>
                )
            })}
        </Stack>
    )
}
