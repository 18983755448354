import { type MouseEvent, useCallback, useMemo, useState, useEffect } from 'react'
import { generatePath, useHistory, useParams } from 'react-router-dom'

import { Box, Grid, LinearProgress } from '@mui/material'

import {
    type BrandKitResponseType,
    useAuth,
    useBoolean,
    useBrandKit,
    useServiceUsage,
    useRoles
} from 'hooks'
import { handleRequest, host, logToAnalytics } from 'modules'

import { Banner } from './Banner'
import { BrandKitHeader } from './BrandKitHeader'

import { DocumentTitle, UpgradePlanPopup } from 'UI/Components'
import {
    BrandKitCard,
    SlideTemplates,
    ExtensionLogo,
    FontSettings,
    LogoAndFavicon,
    Color,
    StepAppearance,
    VideoTemplates,
    Voiceover
} from './sections'

import { type UploadFileType } from 'app/types'
import { paths } from 'app/paths'

export const brandKitRequestPath = '/c/v1/brand-kit'

export const cardLimits = {
    colors: 5,
    logoAndFavicon: 5,
    cover: 10,
    end: 10,
    outro: 5,
    intro: 5,
    qgHighlightColor: 50,
    background: 50,
    audioBackground: 5,
    stepsNumberingColor: 50,
    fontColor: 50,
    fontFillColor: 50
}

const eventSchema = {
    ext: 'extensionLogo',
    'logo&favicon': 'logo&faviconLogo',
    audio: 'audioBackground'
}

const PageBrandKit = () => {
    const { token, orgId } = useAuth()
    const { isSuperAdmin } = useRoles()

    const history = useHistory()

    const { id: brandKitId } = useParams<{ id: string }>()

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

    const uploading = useBoolean()

    const { isFreePlan } = useServiceUsage()

    const {
        multiBrandKitData,
        revalidateMultiBrandKit,
        multiBrandKitLoading,
        revalidateBrandKit,
        brandKitData,
        brandKitBackground,
        brandKitStepNumberingColor,
        brandKitHighlightColor,
        videoOutputSettings,
        documentOutputSettings,
        brandKitLoading,
        $editBrandKit,
        $deleteFromBrandKit,
        $addToBrandKit,
        $setActiveBrandKit
    } = useBrandKit(brandKitId)

    const historyReplace = history.replace
    useEffect(() => {
        // the org has only one brand kit, select it by default
        if (multiBrandKitData?.length === 1) {
            historyReplace(
                generatePath(paths.brandKitDetails, {
                    id: multiBrandKitData[0].id
                })
            )
        }
    }, [historyReplace, multiBrandKitData, brandKitId])

    const setUploading = uploading.set

    const uploadFile = useCallback(
        (body: UploadFileType): Promise<BrandKitResponseType> => {
            setUploading(true)
            const formData = new FormData()

            Object.entries<any>(body).forEach(([key, value]) => {
                formData.append(key, value)
            })

            if (brandKitId) {
                formData.append('brandKitId', brandKitId)
            }

            const url = body.type === 'audio' ? 'audio-background' : 'upload-image'
            const path = `${host}${brandKitRequestPath}/${url}`

            return fetch(path, {
                method: 'POST',
                headers: {
                    authorization: `Bearer ${token}`
                },
                body: formData
            })
                .then(handleRequest('POST'))
                .then(data => {
                    const event = eventSchema[body.type]

                    logToAnalytics('brandkitEdit', {
                        actionType: 'edit',
                        objectType: event,
                        userSelection: event
                    })

                    revalidateBrandKit()

                    return data
                })
                .finally(() => setUploading(false))
        },
        [revalidateBrandKit, setUploading, token, brandKitId]
    )

    const isLoading =
        $editBrandKit.isLoading ||
        $deleteFromBrandKit.isLoading ||
        $addToBrandKit.isLoading ||
        $setActiveBrandKit.isLoading ||
        uploading.isTrue ||
        brandKitLoading

    const editBrandKit = $editBrandKit.mutate
    const addToBrandKit = $addToBrandKit.mutate
    const deleteFromBrandKit = $deleteFromBrandKit.mutate
    const setActiveBrandKit = $setActiveBrandKit.mutate

    const validateClick = useCallback(
        (e: MouseEvent<HTMLElement>, callback: () => void) => {
            if (isFreePlan && !isSuperAdmin) {
                setAnchorEl(e.currentTarget)
            } else {
                callback()
            }
        },
        [isFreePlan, isSuperAdmin]
    )
    const popupProps = useMemo(
        () => ({
            isLoading,
            anchorEl,
            setAnchorEl,
            validateClick
        }),
        [anchorEl, setAnchorEl, validateClick, isLoading]
    )

    const props = useMemo(() => {
        function propsGenerator<T>(func: (data: T) => Promise<BrandKitResponseType>) {
            return (data: T) => {
                return func({
                    ...data,
                    orgId,
                    ...(brandKitId && { id: brandKitId })
                })
            }
        }

        return {
            isLoading,
            popupProps,
            revalidateBrandKit,
            onEdit: propsGenerator(editBrandKit),
            onAdd: propsGenerator(addToBrandKit),
            onDelete: propsGenerator(deleteFromBrandKit),
            onSetActive: propsGenerator(setActiveBrandKit)
        }
    }, [
        isLoading,
        popupProps,
        revalidateBrandKit,
        editBrandKit,
        orgId,
        brandKitId,
        addToBrandKit,
        deleteFromBrandKit,
        setActiveBrandKit
    ])

    const sortedByAlphabetBrandKitData = useMemo(() => {
        if (!multiBrandKitData) return []

        return multiBrandKitData.sort((a, b) => {
            return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        })
    }, [multiBrandKitData])

    if (multiBrandKitLoading && !multiBrandKitData) return <LinearProgress />

    return (
        <Box px={3}>
            <DocumentTitle title="Brand Kit" />
            <BrandKitHeader />

            <Box mt={4} />
            <Banner isFreePlan={isFreePlan} setAnchorEl={setAnchorEl} />
            <Box height={5} mt={1}>
                {brandKitLoading && <LinearProgress />}
            </Box>

            <UpgradePlanPopup
                {...popupProps}
                hideSeePlanFeatures={true}
                openFeatures={true}
                title="Unlock the Brand Kit"
                text="Take control of your brand. Logo, colors, backgrounds, fonts… the works!"
            />

            {brandKitData && brandKitId ? (
                <>
                    <SlideTemplates
                        {...props}
                        kind="cover"
                        background={brandKitData.background}
                        values={brandKitData.cover}
                        cardsLimit={cardLimits.cover}
                        title="Cover"
                        subtitle="For step-by-step videos you can use different covers for different types of content"
                    />
                    <VideoTemplates
                        {...props}
                        kind="intro"
                        values={brandKitData.intro}
                        cardsLimit={cardLimits.intro}
                        title="Video Intro"
                        subtitle="Captivate your audience from the start with your intro video"
                    />
                    <SlideTemplates
                        {...props}
                        kind="end"
                        background={brandKitData.background}
                        values={brandKitData.end}
                        cardsLimit={cardLimits.end}
                        title="Ending"
                        subtitle="Select the ending slide for step-by-step videos"
                    />
                    <VideoTemplates
                        {...props}
                        kind="outro"
                        values={brandKitData.outro}
                        cardsLimit={cardLimits.outro}
                        title="Video Outro"
                        subtitle="Wrap up with impact by adding your branded outro video"
                    />
                    <Color {...props} values={brandKitData.color} />
                    <LogoAndFavicon
                        {...props}
                        values={brandKitData.logoAndFavicon}
                        uploadFile={uploadFile}
                    />
                    <FontSettings
                        {...props}
                        values={{
                            font: brandKitData.font,
                            fontSize: brandKitData.fontSize,
                            fontColor: brandKitData.fontColor,
                            fontFillColor: brandKitData.fontFillColor
                        }}
                    />
                    <StepAppearance
                        {...props}
                        values={{
                            background: brandKitData.background,
                            colors: brandKitData.color,
                            stepsNumberingColor: brandKitData.stepsNumberingColor,
                            qgHighlightColor: brandKitData.qgHighlightColor,
                            videoOutputSettings: videoOutputSettings,
                            documentOutputSettings: documentOutputSettings
                        }}
                        active={{
                            background: brandKitBackground,
                            qgHighlightColor: brandKitHighlightColor,
                            stepsNumberingColor: brandKitStepNumberingColor
                        }}
                    />
                    <Voiceover {...props} value={brandKitData.voiceover} />
                    <ExtensionLogo
                        {...props}
                        uploadFile={uploadFile}
                        values={brandKitData.extensionLogo}
                    />
                </>
            ) : (
                multiBrandKitData &&
                multiBrandKitData?.length > 1 && (
                    <Grid container spacing={2} mt={2} style={{ flexWrap: 'wrap', marginLeft: 0 }}>
                        {sortedByAlphabetBrandKitData.map(item => (
                            <Grid item key={item.id}>
                                <BrandKitCard
                                    revalidateMultiBrandKit={revalidateMultiBrandKit}
                                    selectedBrandKit={item}
                                />
                            </Grid>
                        ))}
                    </Grid>
                )
            )}
        </Box>
    )
}

export default PageBrandKit
