import { useMemo } from 'react'

import { useQuery, useAuth, useDataMutation } from 'hooks'
import { type OrganizationType, PRIVACY_SETTINGS_OPTIONS } from 'app/types'

export const ORG_SHARE_OPTIONS = {
    public: 'public',
    anyoneInOrg: 'anyoneInOrg',
    private: 'private'
} as const

export const useOrganization = (propsOrgId?: string) => {
    const { orgId } = useAuth()

    const $organization = useQuery<OrganizationType>(
        `/c/v1/organization?orgId=${propsOrgId || orgId}`,
        {
            method: 'GET'
        }
    )

    return useMemo(
        () => ({
            isDomainEditNotAllowed: $organization.data?.isDomainEditNotAllowed || false,
            name: $organization.data?.name || '',
            logo: $organization.data?.logo || '',
            domains: $organization.data?.domains || [],
            id: $organization.data?.id || '',
            vanityUrl: $organization.data?.vanityUrl || '',
            defaultSpaces: $organization.data?.defaultSpaces || [],
            extLogo: $organization.data?.extLogo || '',
            extColor: $organization.data?.extColor || '#fff',
            defaultSharingSettings:
                $organization.data?.defaultSharingSettings || ORG_SHARE_OPTIONS.private,
            allowSsoOnly: $organization.data?.allowSsoOnly || false,
            defaultLanguage: $organization.data?.defaultLanguage,
            scheduleURL: $organization.data?.scheduleURL,
            requestRouting: $organization.data?.requestRouting || '',
            privacySettings:
                $organization.data?.privacySettings || PRIVACY_SETTINGS_OPTIONS.internal,
            isPosterInEmbedON: $organization.data?.isPosterInEmbedON || false,
            isLoading: $organization.isValidating,
            revalidateOrgData: $organization.mutate
        }),
        [$organization.data, $organization.isValidating, $organization.mutate]
    )
}

export type UpdateOrganizationPayloadType = Partial<{
    name: OrganizationType['name']
    domains: OrganizationType['domains']
    scheduleURL: OrganizationType['scheduleURL']
    requestRouting: OrganizationType['requestRouting']
    defaultLanguage: OrganizationType['defaultLanguage']
    defaultSpaces: OrganizationType['defaultSpaces']
    defaultSharingSettings: OrganizationType['defaultSharingSettings']
    allowSsoOnly: OrganizationType['allowSsoOnly']
    isPosterInEmbedON: OrganizationType['isPosterInEmbedON']
}>

export const useUpdateOrganization = ({
    orgId,
    onSuccess = () => {}
}: {
    orgId: string
    onSuccess?: () => void
}) => {
    const { orgId: initialOrgId } = useAuth()

    const $updateOrganization = useDataMutation<
        UpdateOrganizationPayloadType,
        { message: string },
        Error
    >(`/c/v1/organization?orgId=${orgId || initialOrgId}`, 'PUT', { onSuccess })

    return $updateOrganization
}
