import { memo } from 'react'

import { Checkbox } from '@guidde/design-system'
import { faFolderPlus, faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons'

import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView'
import { Box, Stack } from '@mui/material'

import { type FolderType } from 'hooks'

import { CustomTreeItem, actionId, openOnlyId } from './CustomTreeItem'
import { ActionIcon } from './ActionIcon'
import { CreateNewTargetInput } from './CreateNewTargetInput'
import {
    type TSelectedDataFolder,
    getFolderCheckBoxTooltipText,
    getNewFolderButtonTooltipText,
    maxFolderNesting
} from '../utils'
import { type PlaybookModeType } from 'app/types'
import { maxFolderNameLength } from 'UI/Components'

type Props = {
    folders: Array<FolderType>
    nestingLevel?: number
    isSpaceDisabled?: boolean
    hasSpaceEditRole: boolean
    shouldDisableFolderSelect: boolean
    isMultiplePlaybook: boolean
    playbookMode?: PlaybookModeType
    getSelectedFolderData: (folderId: string) => TSelectedDataFolder | null
    onToggleNewFolderInput: (folderId: string) => void
    onToggleFolderCheckbox: (folder: FolderType) => void
    onCreateNewFolder: (folderName: string, parentFolderId?: string) => void
    onOpenFolder: (folderIds: Array<string>) => void
}

export const Folders = memo(
    ({
        folders,
        nestingLevel = 1,
        shouldDisableFolderSelect,
        isMultiplePlaybook,
        isSpaceDisabled,
        playbookMode,
        hasSpaceEditRole,
        onCreateNewFolder,
        getSelectedFolderData,
        onToggleFolderCheckbox,
        onOpenFolder,
        onToggleNewFolderInput
    }: Props) => {
        if (!folders.length) return null

        const isMaxLevelOfNesting = nestingLevel === maxFolderNesting

        return (
            <SimpleTreeView>
                {folders.map((folder, i) => {
                    const selectFolder = getSelectedFolderData(folder.id)

                    const isSelected = Boolean(selectFolder?.isSelected)
                    const isDisabled = Boolean(selectFolder?.isDisabled)

                    const showNewFolderInput = selectFolder?.showNewFolderInput
                    const isAnotherSpaceFolderSelected = !isSelected && isDisabled

                    const checkboxTooltipText = getFolderCheckBoxTooltipText({
                        isAnotherSpaceFolderSelected,
                        isMaxLevelOfNesting,
                        isMultiplePlaybook,
                        playbookMode,
                        hasSpaceEditRole
                    })

                    const newFolderTooltip = getNewFolderButtonTooltipText({
                        isMaxLevelOfNesting,
                        isSomeFolderSelected: shouldDisableFolderSelect,
                        isMultiplePlaybook,
                        playbookMode,
                        hasSpaceEditRole
                    })

                    return (
                        <CustomTreeItem
                            key={folder.id + i}
                            itemId={folder.id}
                            collapse={!folder.nestedFolders.length}
                            labelStyles={{
                                fontSize: 12,
                                fontWeight: 400
                            }}
                            actionControls={
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={0.5}
                                    height="100%"
                                    sx={{
                                        color: theme => theme.palette.grey[600]
                                    }}
                                >
                                    <ActionIcon
                                        tooltip={newFolderTooltip}
                                        id={openOnlyId}
                                        disabled={
                                            isMaxLevelOfNesting ||
                                            shouldDisableFolderSelect ||
                                            isSpaceDisabled
                                        }
                                        onClick={() => onToggleNewFolderInput(folder.id)}
                                        icon={faFolderPlus}
                                        shouldHighlightChildIcon={!isMaxLevelOfNesting}
                                    />

                                    <ActionIcon
                                        id={actionId}
                                        tooltip="Open Folder"
                                        onClick={() => onOpenFolder([folder.id])}
                                        disabled={isSpaceDisabled}
                                        icon={faArrowUpRightFromSquare}
                                    />
                                </Stack>
                            }
                            padding="5px 12px 5px 0"
                            label={
                                <Checkbox
                                    checked={isSelected}
                                    disabled={isDisabled || isSpaceDisabled}
                                    disabledReason={checkboxTooltipText}
                                    onChange={() => onToggleFolderCheckbox(folder)}
                                    indeterminate={false}
                                    label={folder.name}
                                />
                            }
                        >
                            <Box>
                                {showNewFolderInput && (
                                    <CreateNewTargetInput
                                        onCreate={name => onCreateNewFolder(name, folder.id)}
                                        onDiscard={() => onToggleNewFolderInput(folder.id)}
                                        target="folder"
                                        maxLength={maxFolderNameLength}
                                    />
                                )}

                                <Folders
                                    onToggleFolderCheckbox={onToggleFolderCheckbox}
                                    getSelectedFolderData={getSelectedFolderData}
                                    onToggleNewFolderInput={onToggleNewFolderInput}
                                    onCreateNewFolder={onCreateNewFolder}
                                    folders={folder.nestedFolders}
                                    isSpaceDisabled={isSpaceDisabled}
                                    hasSpaceEditRole={hasSpaceEditRole}
                                    isMultiplePlaybook={isMultiplePlaybook}
                                    playbookMode={playbookMode}
                                    shouldDisableFolderSelect={shouldDisableFolderSelect}
                                    nestingLevel={nestingLevel + 1}
                                    onOpenFolder={foldersIds => {
                                        onOpenFolder([folder.id, ...foldersIds])
                                    }}
                                />
                            </Box>
                        </CustomTreeItem>
                    )
                })}
            </SimpleTreeView>
        )
    }
)
