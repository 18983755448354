import { type ReactNode, memo, useContext, useMemo } from 'react'
import { generatePath, useHistory } from 'react-router-dom'

import { TextOverflow } from '@guidde-co/shared.textoverflow'

import { styled } from '@mui/system'
import { AppBar, Box, Toolbar, Tooltip, Typography } from '@mui/material'

import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'

import { ReactComponent as InsightsIcon } from 'assets/icons/menu/icon-insights.svg'
import { ReactComponent as SpaceIcon } from 'assets/icons/add-to-space.svg'

import { paths } from 'app/paths'
import { type AnyPlaybookType, PlaybookMode } from 'app/types'

import { useAuth, useBoolean, useDuplicate, useRoles, useWindowView } from 'hooks'
import { isPlaylist, isQG, logToAnalytics } from 'modules'

import {
    BackButton,
    DuplicateQGLoader,
    PlaybookAnalytics,
    ShareToPlaylist,
    ShareToSpaceDialog,
    SpacedGroup,
    useMenuStatus,
    VideoPanelControls,
    VideoStatusContext
} from 'UI/Components'
import { getDrawerWidth } from 'UI/Components/Menu/common'

const StyledAppBar = styled(AppBar, { shouldForwardProp: prop => prop !== 'drawerWidth' })<{
    drawerWidth: number
}>(({ theme, drawerWidth }) => ({
    width: `calc(100% - ${drawerWidth}px)`,
    boxShadow: 'none',
    color: '#000000DE',
    backgroundColor: 'transparent',
    // @ts-ignore missing in type
    transition: theme.transitions.create(['margin', 'width'], {
        // @ts-ignore missing in type
        easing: theme.transitions.easing.sharp,
        // @ts-ignore missing in type
        duration: theme.transitions.duration.enteringScreen
    })
}))

const routeSchema = {
    playbook: paths.editPlaybook,
    playlist: paths.editPlaylist,
    quickGuidde: paths.editQuickGuidde
} as const

type Props = {
    playbook: AnyPlaybookType
    revalidatePlaybook: () => void
    isEditMode?: boolean
    stickyHeader?: boolean
}

export const PlaybookHeader = memo(
    ({ playbook, revalidatePlaybook, isEditMode, stickyHeader }: Props) => {
        const history = useHistory()

        const { isUploadSuccess } = useContext(VideoStatusContext)

        const { isMobileView } = useWindowView()
        const { uid, orgId } = useAuth()

        const { duplicateQG, duplicatePlaylist, duplicationIsLoading, isDuplicationAvailable } =
            useDuplicate({
                uploadedByOrgId: playbook.uploadedByOrgId
            })

        const { isContentManager, isSuperAdmin, isAdmin, isViewer, isMember } = useRoles(
            playbook.uploadedByOrgId
        )

        const isOwner = uid === playbook.creator_uid
        const canEdit = isOwner || isContentManager

        const disableEdit = isQG(playbook) && !isUploadSuccess

        const hideAnalytics =
            (orgId !== playbook.uploadedByOrgId && !isSuperAdmin) ||
            (!isOwner && !isContentManager && !isAdmin)

        const openEditPage = () => {
            history.push({
                pathname: generatePath(routeSchema[playbook.mode], {
                    playbookId: playbook.id
                })
            })
        }

        const analyticsDialog = useBoolean()
        const openAnalyticsDialog = analyticsDialog.setTrue
        const showAddToDialog = useBoolean()
        const addToSpaceDialog = useBoolean()

        const controls = useMemo(() => {
            if (isPlaylist(playbook)) {
                return [
                    {
                        title: 'View analytics',
                        icon: <InsightsIcon style={{ color: '#000000DE' }} />,
                        hidden: hideAnalytics,
                        onClick: () => {
                            openAnalyticsDialog()
                            logToAnalytics('viewAnalytics_clicked', {
                                source: 'playlist',
                                playbookId: playbook.id
                            })
                        }
                    },
                    {
                        title: 'Add to Space',
                        icon: <SpaceIcon />,
                        hidden: !isMember,
                        onClick: addToSpaceDialog.setTrue
                    },
                    {
                        title: 'Make a Copy',
                        icon: <FileCopyOutlinedIcon />,
                        hidden: !isDuplicationAvailable,
                        onClick: () => duplicatePlaylist(playbook),
                        showAfterClick: true
                    }
                ]
            }

            return [
                {
                    title: 'Save to playlist',
                    icon: <PlaylistAddIcon />,
                    hidden: isViewer,
                    onClick: showAddToDialog.setTrue
                },
                {
                    title: 'Add to Space',
                    icon: <SpaceIcon />,
                    hidden: !isMember,
                    onClick: addToSpaceDialog.setTrue
                },
                {
                    title: 'Make a Copy',
                    icon: <FileCopyOutlinedIcon />,
                    hidden: !isQG(playbook) || !isDuplicationAvailable,
                    onClick: () => (isQG(playbook) ? duplicateQG(playbook) : {}),
                    isLoading: duplicationIsLoading,
                    showAfterClick: true
                },
                {
                    title: 'View analytics',
                    icon: <InsightsIcon />,
                    hidden: hideAnalytics,
                    onClick: () => {
                        openAnalyticsDialog()
                        logToAnalytics('viewAnalytics_clicked', {
                            source: 'video',
                            playbookId: playbook.id
                        })
                    }
                }
            ]
        }, [
            duplicationIsLoading,
            isDuplicationAvailable,
            addToSpaceDialog.setTrue,
            openAnalyticsDialog,
            duplicatePlaylist,
            isMember,
            duplicateQG,
            hideAnalytics,
            isViewer,
            playbook,
            showAddToDialog.setTrue
        ])

        const Wrapper = stickyHeader ? StickyHeader : Box

        if (isMobileView) return null

        return (
            <Wrapper>
                <DuplicateQGLoader isOpen={duplicationIsLoading} />
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                    mb={1}
                >
                    <SpacedGroup>
                        <BackButton text="" />

                        <TextOverflow lineClamp={1}>
                            <Tooltip title={playbook.title}>
                                <Typography
                                    data-test="video-title"
                                    style={{
                                        fontSize: '20px',
                                        fontWeight: 600,
                                        maxWidth: '600px',
                                        marginRight: '4px'
                                    }}
                                >
                                    {playbook.title}
                                </Typography>
                            </Tooltip>
                        </TextOverflow>
                    </SpacedGroup>

                    <PlaybookAnalytics
                        playbook={playbook}
                        isOwner={isOwner}
                        isOpen={analyticsDialog.isTrue}
                        onClose={analyticsDialog.setFalse}
                    />

                    <SpacedGroup>
                        <VideoPanelControls
                            canEdit={canEdit}
                            hideEdit={isEditMode}
                            hideDelete={!isEditMode}
                            onEditClick={openEditPage}
                            disableEdit={disableEdit}
                            playbook={playbook}
                            revalidate={revalidatePlaybook}
                            controls={controls}
                        />
                    </SpacedGroup>
                </Box>
                {showAddToDialog.isTrue && playbook.mode !== PlaybookMode.Playlist && (
                    <ShareToPlaylist
                        onClose={showAddToDialog.setFalse}
                        selectedPlaybooks={[playbook]}
                    />
                )}
                {addToSpaceDialog.isTrue && (
                    <ShareToSpaceDialog
                        isOpen={true}
                        playbookMode={playbook.mode}
                        onClose={addToSpaceDialog.setFalse}
                        playbooksIds={[playbook.id]}
                    />
                )}
            </Wrapper>
        )
    }
)

type StickyHeaderProps = {
    children: ReactNode
}

const StickyHeader = ({ children }: StickyHeaderProps) => {
    const { isMenuClosed } = useMenuStatus()

    return (
        <>
            <StyledAppBar drawerWidth={getDrawerWidth(isMenuClosed)}>
                <Toolbar variant="dense" sx={{ height: 'auto', position: 'relative' }}>
                    <Box
                        position="absolute"
                        bottom={0}
                        left={40}
                        right={40}
                        borderBottom="2px solid rgba(9, 12, 16, 0.08)"

                        /* bgcolor="#E0E0E0" */
                    />
                    <Box bgcolor="#FAFAFA" width="100%" display="flex" p={2} pb={0}>
                        {children}
                    </Box>
                </Toolbar>
            </StyledAppBar>
            <Toolbar variant="dense" sx={{ height: 40, minHeight: 40 }} />
        </>
    )
}
