import { useContext, useEffect, useState } from 'react'

import { Box, CircularProgress, LinearProgress } from '@mui/material'

import { defaultSpeakingRate, enUScode, useSpeakerOptions } from 'UI/Routes/quick-guidde/hooks'
import {
    SpeakerSelection,
    type SpeakerSelectionDialogProps
} from 'UI/Routes/quick-guidde/LeftPanel/VoiceOverPanel/speakers'

import { StepsContext, VideoStatusContext } from 'UI/Components'
import { T2sBlockedMessage } from './T2sBlockedMessage'

import { ReactComponent as RollNext } from 'assets/icons/roll-next.svg'

import { type QuickGuiddeType, type SpeakerType } from 'app/types'

import { useDataMutation, useLocalStorage } from 'hooks'
import { logToAnalytics, playbookToAnalyticsProps } from 'modules'
import { Button, Typography } from '@guidde/design-system'

import VoiceoverAnalytics from 'analytics/voiceover'

type Props = {
    isBlocked: boolean
    playbook: QuickGuiddeType
    onClose: () => void
    onDone: () => void
}

export const ConvertStep = ({ playbook, isBlocked, onClose, onDone }: Props) => {
    const { getInitialSpeaker, isLoading: isSpeakerOptionsLoading } = useSpeakerOptions()
    const { checkVideoStatus, onConvert } = useContext(VideoStatusContext)
    const { stepsProcessing } = useContext(StepsContext)

    const qgLanguageCode = playbook?.magicCapture?.language?.langCode || enUScode

    const [speakingRate] = useLocalStorage('speakingRate', defaultSpeakingRate)

    const [speakerConfig, setSpeakerConfig] = useState<null | SpeakerType>(null)

    useEffect(() => {
        if (!isSpeakerOptionsLoading && !speakerConfig) {
            setSpeakerConfig(getInitialSpeaker(qgLanguageCode))
        }
    }, [isSpeakerOptionsLoading, qgLanguageCode, getInitialSpeaker, speakerConfig])

    const $convertT2s = useDataMutation<
        { playbookId: string; config: SpeakerType },
        unknown,
        Error
    >('/c/v1/quickguidde/convertT2s', 'POST', {
        onSuccess: () => {
            checkVideoStatus().then(() => {
                stepsProcessing.setFalse()
                onConvert()
            })
        }
    })

    const handleAudioPlay = (speaker: SpeakerType) => {
        VoiceoverAnalytics.voiceoverPlaySpeakerPreview({
            playbookId: playbook.id,
            activeStep: 0,
            speaker,
            source: 'magicCapture'
        })
    }

    const handleGenerateButtonClick = () => {
        if (!speakerConfig) return

        logToAnalytics('magic_capture_converted', {
            languageCode: speakerConfig.langCode,
            languageName: speakerConfig.langName,
            ...playbookToAnalyticsProps(playbook)
        })

        stepsProcessing.setTrue()
        $convertT2s
            .mutate({
                playbookId: playbook.id,
                config: { ...speakerConfig, speakingRate }
            })
            .then(onDone)
    }

    const handleIgnoreButtonClick = () => {
        logToAnalytics('magic_capture_ignored', {
            ...playbookToAnalyticsProps(playbook)
        })
        onConvert()
    }

    const handleSpeakerSelection: SpeakerSelectionDialogProps['onSave'] = (_e, speaker) => {
        setSpeakerConfig(speaker)
    }

    if (isBlocked) return <T2sBlockedMessage onClose={onClose} />

    if (!speakerConfig) {
        return (
            <Box
                width="100%"
                height="335px"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <CircularProgress />
            </Box>
        )
    }

    return (
        <Box width="100%">
            {$convertT2s.isLoading && <LinearProgress />}

            <Box p={2}>
                <Typography variant="text" fontWeight="bold">
                    Generate natural sounding voiceovers <br /> from the step descriptions
                </Typography>

                <Box my={2}>
                    <Typography variant="text">01. Review the descriptions.</Typography>
                    <Typography variant="text">02. Choose speaker.</Typography>
                    <Typography variant="text">03. Generate!</Typography>
                </Box>

                <Box mb={3} p={1} display={speakerConfig ? 'block' : 'none'}>
                    <SpeakerSelection
                        currentSpeaker={speakerConfig}
                        onSave={handleSpeakerSelection}
                        onAudioPlay={handleAudioPlay}
                        source="magicCapture"
                    />
                </Box>

                <Button
                    disabled={$convertT2s.isLoading}
                    startIcon={<RollNext />}
                    onClick={handleGenerateButtonClick}
                    variant="contained"
                >
                    Generate
                </Button>

                <Box
                    sx={{
                        '& button': {
                            textDecoration: 'underline'
                        }
                    }}
                >
                    <Button variant="text" color="primary" onClick={handleIgnoreButtonClick}>
                        Ignore
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}
