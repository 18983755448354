import { useContext, useState } from 'react'

import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Box, Button, CircularProgress, Divider } from '@mui/material'

import {
    VoiceOverLoader,
    LanguageDropdown,
    VoiceOverPreview
} from 'UI/Routes/quick-guidde/LeftPanel/VoiceOverPanel'
import { useLayers } from 'UI/Routes/quick-guidde/CanvasEditor'
import { VoiceOverContext } from 'UI/Routes/quick-guidde/VoiceOverProvider'

import { S2tBanner } from './S2tBanner'
import { AudioRecorder } from './AudioRecorder'
import { VoiceOverUploader } from './VoiceOverUploader'

import { useAuth, useDataMutation, useNotification } from 'hooks'
import {
    type OptionType,
    delay,
    envConfig,
    logToAnalytics,
    option,
    round,
    uuid,
    getAudioDuration,
    getFirebaseUrl,
    uploadWithSignedUrl
} from 'modules'

import { addNewLayer, selectLayer, setActiveStep, setAudioNote } from 'ducks'
import { type SubtitlesType, type QuickGuiddeType, Shape } from 'app/types'

type Props = {
    playbook: QuickGuiddeType
}

type GenerateTextPayload = {
    audioUrl: string
    languageCode: string
    languageName: string
    encoding: string
    activeStep: number
    playbookId: string
    stepId: string
}

type GenerateTextResponse = {
    subtitles: SubtitlesType
    audioNote: {
        text: string
        markdown: string
    }
    subGenerationId?: string
}

export const extensionSchema: { [key: Blob['type']]: string } = {
    'audio/wav': 'wav',
    'audio/mpeg': 'mp3',
    'audio/webm': 'webm'
} as const

export const SpeechToTextTab = ({ playbook }: Props) => {
    const dispatch = useDispatch()

    const { showErrorNotification } = useNotification()

    const { playbookId } = useParams<{ playbookId: string }>()

    const { uid, photoURL } = useAuth()
    const { audioImage, audioSVG } = useLayers()

    const { userPreferences } = useSelector(state => state.configs)
    const { s2tBanner } = useSelector(state => state.configs.userPreferences)

    const {
        present: { activeStep, steps }
    } = useSelector(state => state.qgEditor)

    const { processingVideoStepsId, voiceOverProcessing, setVoiceOverProcessing } =
        useContext(VoiceOverContext)

    const { language: playbookLanguage } = playbook

    const playbookLanguageOption = playbookLanguage
        ? option(playbookLanguage.langCode, playbookLanguage.langName)
        : null

    const [language, setLanguage] = useState<OptionType | null>(playbookLanguageOption)

    const { audioNote, tempAudioNote, layers } = steps?.[activeStep]

    const handleTextGenerated = async ({
        text,
        markdown,
        activeStep,
        subtitles,
        audioUrl,
        languageCode,
        languageName,
        subGenerationId
    }: {
        activeStep: number
        subtitles: SubtitlesType
        audioUrl: string
        languageCode: string
        languageName: string
        text: string
        markdown: string
        subGenerationId?: string
    }) => {
        const audioLength = await getAudioDuration(audioUrl)

        dispatch(setActiveStep(activeStep))

        dispatch(
            setAudioNote({
                audioNote: {
                    type: 'speechToText',
                    audioUrl,
                    audioDuration: round(audioLength),
                    languageCode,
                    languageName,
                    text,
                    markdown,
                    isUserEdited: true
                },
                subtitles,
                ...(subGenerationId && { subGenerationId })
            })
        )

        logToAnalytics('voiceover_add', {
            voiceOverType: 'speechToText',
            playbookId,
            audioLength,
            languageCode,
            activeStep,
            speaker: null
        })

        delay(500).then(() => setVoiceOverProcessing(false))
    }

    const addAudioLayer = () => {
        // Do not add audio layer if disabled in user preferences
        const isEnabled = userPreferences['s2tAvatar']
        if (!isEnabled) return
        // Ignore video step

        const videoStep = layers.find(layer => layer.type === Shape.Video)
        if (videoStep) return

        // We should add audio layer only if the one doesn't exist
        const hasAudioLayer = Boolean(layers.find(layer => layer.type === Shape.AudioCircle))
        if (hasAudioLayer) return

        // if user has avatar - render default audioImage
        if (photoURL) {
            dispatch(addNewLayer(audioImage))
            dispatch(selectLayer(audioImage.id))
        }
        // otherwise, render audioSVG
        else {
            dispatch(addNewLayer(audioSVG))
            dispatch(selectLayer(audioSVG.id))
        }
    }

    const $generateText = useDataMutation<GenerateTextPayload, GenerateTextResponse, Error>(
        '/c/v1/quickguidde/speech-to-text',
        'POST',
        {
            onSuccess: (data, { audioUrl, languageCode, languageName, activeStep }) => {
                handleTextGenerated({
                    activeStep,
                    subtitles: data.subtitles,
                    text: data.audioNote.text,
                    markdown: data.audioNote.markdown,
                    subGenerationId: data.subGenerationId,
                    audioUrl,
                    languageCode,
                    languageName
                })
            },
            onFailure: error => {
                console.error('Failed to generate text', error)
                setVoiceOverProcessing(false)
            }
        }
    )

    const handleUploading = async ({
        fileExtension,
        blobFile,
        languageCode,
        languageName
    }: {
        blobFile: Blob
        fileExtension: string
        languageCode: string
        languageName: string
    }) => {
        setVoiceOverProcessing(true)

        const bucket = `gs://${envConfig.firebaseConfig.projectId}-temporary`
        const storagePath = `audioUploads/${uid}/${playbookId}_${uuid()}.${fileExtension}`

        await uploadWithSignedUrl(bucket + '/' + storagePath, blobFile)
        const url = await getFirebaseUrl(storagePath, true)

        if (!languageCode || languageCode === 'other') {
            await handleTextGenerated({
                activeStep,
                subtitles: [{ text: '', start: 0, end: 3 }],
                audioUrl: url,
                languageCode,
                languageName,
                text: '',
                markdown: ''
            }).then(() => delay(1000).then(addAudioLayer))
            return
        }

        await $generateText
            .mutate({
                audioUrl: url,
                languageCode,
                languageName,
                encoding: 'WEBM_OPUS',
                activeStep,
                playbookId,
                stepId: steps[activeStep].id
            })
            .then(() => delay(1000).then(addAudioLayer))
    }

    if (voiceOverProcessing) {
        return <VoiceOverLoader title="Creating transcript" />
    }

    if (processingVideoStepsId.includes(steps[activeStep].id)) {
        return (
            <Box mt={3} display="flex" justifyContent="center">
                <CircularProgress size={100} />
            </Box>
        )
    }

    if (audioNote && audioNote.type === 'speechToText') {
        return (
            <VoiceOverPreview
                onEdit={({ markdown }) => {
                    dispatch(
                        setAudioNote({
                            audioNote: {
                                ...audioNote,
                                text: markdown,
                                markdown
                            }
                        })
                    )
                    logToAnalytics('voiceover_edit_transcript', {
                        oldText: audioNote.markdown,
                        playbookId,
                        activeStep,
                        newText: markdown
                    })
                }}
            />
        )
    }

    return (
        <>
            <S2tBanner />

            <Box mt={1}>
                <LanguageDropdown
                    language={language}
                    onDropdownOpen={() =>
                        logToAnalytics('voiceover_recordTab_language_clicked', { playbookId })
                    }
                    onSelect={language => {
                        setLanguage(language)
                        logToAnalytics('voiceover_recordTab_language_selected', {
                            playbookId,
                            language_code: language.value
                        })
                    }}
                    showOther={true}
                    anchorOriginHorizontal="center"
                    transformOriginHorizontal="center"
                />
            </Box>

            <AudioRecorder language={language} />

            {(!audioNote || audioNote?.type === 'defaultSubtitles') && (
                <VoiceOverUploader
                    language={language}
                    playbook={playbook}
                    onUpload={async blobFile => {
                        await handleUploading({
                            blobFile,
                            fileExtension: extensionSchema[blobFile.type],
                            languageCode: language?.value || '',
                            languageName: language?.label || ''
                        })
                    }}
                />
            )}

            <Box
                mt={s2tBanner ? 4 : 0}
                px={s2tBanner ? 0 : 2}
                sx={{
                    ...(!s2tBanner && {
                        '@media (min-height: 750px)': {
                            position: 'absolute',
                            bottom: 16,
                            left: 0
                        },
                        '@media (max-height: 750px)': {
                            marginTop: 8,
                            padding: 0
                        }
                    })
                }}
                width="100%"
                onClick={async () => {
                    if (tempAudioNote?.type !== 'speechToText') return

                    logToAnalytics('voiceover_addVoiceoverBtn_clicked', { playbookId })

                    const fileExtension = extensionSchema[tempAudioNote.blobFile.type]
                    if (!fileExtension) {
                        showErrorNotification('Unsupported audio format')

                        console.error('Audio extension is not defined', tempAudioNote)
                        return
                    }

                    await handleUploading({
                        fileExtension,
                        blobFile: tempAudioNote.blobFile,
                        languageCode: tempAudioNote.languageCode,
                        languageName: tempAudioNote.languageName
                    })
                }}
            >
                <Box mx={-2} mb={2}>
                    <Divider />
                </Box>
                <Button
                    variant="contained"
                    data-test="recorder-add-voiceover-button"
                    disabled={tempAudioNote?.type !== 'speechToText'}
                    fullWidth
                >
                    ADD VOICEOVER
                </Button>
            </Box>
        </>
    )
}
