import { useCallback, useContext, useEffect, useState, type MouseEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Box, Button, CircularProgress, Divider } from '@mui/material'

import { useBoolean } from '@guidde-co/shared.hooks.use-boolean'

import {
    QgTranscript,
    VoiceOverLoader,
    VoiceOverPreview,
    type VoiceOverOnEditData
} from 'UI/Routes/quick-guidde/LeftPanel'
import { useLayers } from 'UI/Routes/quick-guidde/CanvasEditor'
import {
    defaultSpeakingRate,
    enUScode,
    useSpeakerOptions,
    useStepSpeakingRate
} from 'UI/Routes/quick-guidde/hooks'
import { AsyncSubtitlesContext } from 'UI/Routes/quick-guidde/AsyncSubtitlesProvider'
import { VoiceOverContext } from 'UI/Routes/quick-guidde/VoiceOverProvider'

import { SpacedGroup } from 'UI/Components'
import { T2sBanner } from './T2sBanner'

import { useDataMutation } from 'hooks'
import { type OptionType, isDeepEqual, option, round, uuid } from 'modules'

import { setActiveStep, setMultipleAudioNotes, setTempAudioNote } from 'ducks'
import {
    type AudioCircleType,
    type QuickGuiddeType,
    type SpeakerType,
    type StepType,
    type SubtitlesType,
    type TextToSpeechType,
    Shape
} from 'app/types'
import VoiceoverAnalytics from 'analytics/voiceover'
import { SpeakerSelection } from 'UI/Routes/quick-guidde/LeftPanel/VoiceOverPanel/speakers'

type AudioNotePayload = {
    audioNote: {
        text: string
        markdown: string
    }
    stepId: string
    subtitles?: SubtitlesType
    config: SpeakerType
    stepHasAudioLayer: boolean
    isActiveStep: boolean
}

type Payload = {
    playbookId: string
    segments: Array<AudioNotePayload>
    audioImage: AudioCircleType
    activeStep: number
    activeSpeaker: SpeakerType
    t2sAvatar: boolean
}

type Response = Array<
    AudioNotePayload & {
        audioUrl: string
        duration: number
        subtitles: SubtitlesType
        subGenerationId?: string
    }
>

type Props = {
    hasVoiceOverTextToSpeech: boolean | null
    playbook: QuickGuiddeType
}

export const TextToSpeechTab = ({ hasVoiceOverTextToSpeech, playbook }: Props) => {
    const dispatch = useDispatch()
    const { getInitialSpeaker, isLoading } = useSpeakerOptions()

    const { processingStepsId } = useContext(AsyncSubtitlesContext)
    const { setVoiceOverProcessing } = useContext(VoiceOverContext)

    const { playbookId } = useParams<{ playbookId: string }>()
    const { audioImage } = useLayers()

    const { getStepSpeakingRate } = useStepSpeakingRate()

    const { t2sBanner, t2sAvatar } = useSelector(state => state.configs.userPreferences)

    const { activeStep, steps } = useSelector(state => state.qgEditor.present)

    const { audioNote, tempAudioNote, id } = steps?.[activeStep]

    const applyAllSpeakers = useBoolean()

    const markdown = audioNote?.tempMarkdown || audioNote?.markdown || ''

    const [transcriptText, setTranscriptText] = useState<string>(markdown)

    const { language: playbookLanguage } = playbook

    const playbookLanguageOption = playbookLanguage
        ? option(playbookLanguage.langCode, playbookLanguage.langName)
        : null

    const [language, setLanguage] = useState<OptionType | null>(playbookLanguageOption)
    const [speakerConfig, setSpeakerConfig] = useState<null | SpeakerType>(null)

    useEffect(() => {
        const speaker =
            tempAudioNote?.type === 'textToSpeech'
                ? tempAudioNote?.speakerConfig
                : audioNote?.type === 'textToSpeech'
                  ? audioNote?.speakerConfig
                  : null

        if (speaker) {
            setSpeakerConfig(speaker)
            return
        }

        if (!speaker && speakerConfig) return
        if (!speaker && isLoading) return
        const initialSpeaker = getInitialSpeaker(playbookLanguageOption?.value ?? enUScode)
        setSpeakerConfig(initialSpeaker)
    }, [
        speakerConfig,
        activeStep,
        audioNote,
        tempAudioNote,
        isLoading,
        getInitialSpeaker,
        playbookLanguageOption?.value
    ])

    useEffect(() => {
        const shouldResetTranscriptForNonTextToSpeechNote =
            !tempAudioNote || tempAudioNote.type !== 'textToSpeech'

        if (shouldResetTranscriptForNonTextToSpeechNote) {
            history.replaceState('', '')
            setTranscriptText(markdown)
            return
        }

        const shouldUpdateTranscriptToMatchTempMarkdown =
            tempAudioNote?.markdown && transcriptText !== tempAudioNote.markdown

        if (shouldUpdateTranscriptToMatchTempMarkdown) {
            setTranscriptText(tempAudioNote.markdown)
        }

        const shouldUpdateLanguageToMatchTempAudioNote =
            language?.value !== tempAudioNote.speakerConfig.langCode

        if (shouldUpdateLanguageToMatchTempAudioNote) {
            setLanguage(
                option(tempAudioNote.speakerConfig.langCode, tempAudioNote.speakerConfig.langName)
            )
        }

        // Prevent unnecessary re-renders
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tempAudioNote, markdown, activeStep])

    const buttonOnBottom = hasVoiceOverTextToSpeech && !t2sBanner

    const $createTextToSpeech = useDataMutation<Payload, Response, Error>(
        '/c/v1/quickguidde/text-to-speech',
        'POST',
        {
            onSuccess: (data, { activeSpeaker, activeStep, audioImage, t2sAvatar }) => {
                dispatch(setActiveStep(activeStep))

                // If we have multiple steps - we should prepare array of audio notes and add audio layer to each of them
                const multipleT2s = data.map(dataStep => {
                    const isAvatarEnabled = Boolean(t2sAvatar)

                    const { isActiveStep, stepHasAudioLayer } = dataStep

                    const audioLayer = {
                        ...audioImage,
                        id: uuid(),
                        render: {
                            ...audioImage.render,
                            url: dataStep.config.avatarUrl
                        }
                    }

                    return {
                        audioNote: {
                            type: 'textToSpeech' as const,
                            isUserEdited: true,
                            text: dataStep.audioNote.text,
                            markdown: dataStep.audioNote.markdown,
                            audioUrl: dataStep.audioUrl,
                            audioDuration: round(dataStep.duration),
                            speakerConfig: dataStep.config,
                            speakingRate: dataStep.config.speakingRate
                        } as TextToSpeechType,
                        stepId: dataStep.stepId,
                        subtitles: dataStep.subtitles,
                        subGenerationId: dataStep.subGenerationId,
                        // we should add audio layer only if enabled in user preferences or if current step already has it
                        ...((stepHasAudioLayer || (isActiveStep && isAvatarEnabled)) && {
                            audioLayer
                        })
                    }
                })

                dispatch(setMultipleAudioNotes(multipleT2s))

                VoiceoverAnalytics.voiceoverAdd({
                    voiceOverType: 'textToSpeech',
                    playbookId,
                    activeStep,
                    multipleSteps: data.length > 1,
                    languageCode: activeSpeaker.langCode,
                    speaker: activeSpeaker,
                    data
                })
            },
            onFailure: console.error
        }
    )

    const generateSegments = useCallback(
        (
            markdown: string,
            speakingRate: number,
            applyToAllSteps: boolean
        ): Array<AudioNotePayload> => {
            const checkIfStepChanged = (
                audioNote: StepType['audioNote'],
                isActiveStep: boolean
            ): boolean => {
                // Speed rate can be changed for both active and non-active steps
                const stepSpeakingRate =
                    audioNote?.type === 'textToSpeech' && audioNote.speakerConfig.speakingRate
                const isSpeedChanged = stepSpeakingRate
                    ? stepSpeakingRate !== speakingRate
                    : speakingRate !== 1

                if (isSpeedChanged) return true

                // In active step, we need to check if text is changed only
                if (isActiveStep) {
                    const isNewText = audioNote?.markdown !== markdown
                    return isNewText || audioNote.type == 'defaultSubtitles'
                }
                // All the other steps check the speaker change
                else {
                    if (audioNote?.type !== 'textToSpeech') return false
                    // Check if speaker is changed
                    return speakerConfig?.id !== audioNote.speakerConfig?.id
                }
            }

            return steps.flatMap(step => {
                const { audioNote, id } = step
                const isActiveStep = id === steps[activeStep].id

                // Skip non-active steps if `applyToAllSteps` is not checked or no audioNote
                if (!isActiveStep && (!applyToAllSteps || !audioNote)) return []
                // Skip step with no changes
                if (!checkIfStepChanged(audioNote, isActiveStep)) return []

                const stepHasAudioLayer = step.layers.some(
                    layer => layer.type === Shape.AudioCircle
                )

                return {
                    isActiveStep,
                    stepHasAudioLayer,
                    stepId: id,
                    audioNote: {
                        text: isActiveStep ? markdown : audioNote!.text,
                        markdown: isActiveStep ? markdown : audioNote!.markdown
                    },
                    config: {
                        ...(speakerConfig || (audioNote as TextToSpeechType).speakerConfig),
                        speakingRate
                    }
                }
            })
        },
        [activeStep, speakerConfig, steps]
    )

    const handleEdit = ({
        markdown,
        speakingRate = defaultSpeakingRate,
        applyToAllSteps = false
    }: VoiceOverOnEditData) => {
        if (audioNote?.type !== 'textToSpeech') return
        const segments = generateSegments(markdown, speakingRate, applyToAllSteps)

        setVoiceOverProcessing(true)
        $createTextToSpeech
            .mutate({
                playbookId,
                segments,
                activeStep,
                audioImage,
                activeSpeaker: audioNote.speakerConfig,
                t2sAvatar
            })
            .finally(() => setVoiceOverProcessing(false))
    }

    const handleSpeakerSelect = (
        _e: MouseEvent<HTMLElement>,
        speaker: SpeakerType,
        config?: { applyToAll: boolean }
    ) => {
        if (config) applyAllSpeakers.set(config.applyToAll)

        if (!isDeepEqual(speaker, speakerConfig)) {
            setSpeakerConfig(speaker)
        }

        const isTextToSpeechWithUpdatedSpeakerConfig =
            tempAudioNote?.type === 'textToSpeech' &&
            !isDeepEqual(speaker, tempAudioNote.speakerConfig)

        if (isTextToSpeechWithUpdatedSpeakerConfig) {
            dispatch(
                setTempAudioNote({
                    type: 'textToSpeech',
                    text: tempAudioNote.text,
                    markdown: tempAudioNote.markdown,
                    speakerConfig: speaker
                })
            )
        }
    }

    const logAnalyticsOnAudioPlay = (speaker: SpeakerType) => {
        VoiceoverAnalytics.voiceoverPlaySpeakerPreview({
            playbookId,
            activeStep,
            speaker,
            source: 'voiceovert2vTab'
        })
    }

    const handleAddVoiceover = () => {
        if (!speakerConfig) return
        VoiceoverAnalytics.voiceoverAddVoiceoverBtnClicked({
            playbookId,
            textInput: transcriptText
        })

        const segments = generateSegments(
            transcriptText,
            getStepSpeakingRate(steps[activeStep]),
            applyAllSpeakers.isTrue
        )

        setVoiceOverProcessing(true)
        $createTextToSpeech
            .mutate({
                playbookId,
                segments,
                activeStep,
                audioImage,
                activeSpeaker: speakerConfig,
                t2sAvatar
            })
            .finally(() => setVoiceOverProcessing(false))
    }

    if (processingStepsId.includes(id) || $createTextToSpeech.isLoading)
        return <VoiceOverLoader title="Generating voiceover..." />

    if (audioNote && audioNote.type === 'textToSpeech')
        return <VoiceOverPreview showSpeedButton={true} onEdit={handleEdit} />

    return (
        <Box position={hasVoiceOverTextToSpeech ? 'initial' : 'relative'}>
            <T2sBanner showBanner={t2sBanner} disabled={!hasVoiceOverTextToSpeech} />

            {!hasVoiceOverTextToSpeech && (
                <Box
                    sx={theme => ({
                        left: theme.spacing(-2),
                        top: theme.spacing(-2),
                        position: 'absolute',
                        width: 'calc(100% + 32px)',
                        height: 'calc(100% + 32px)',
                        background: 'white',
                        opacity: 0.5,
                        zIndex: 1
                    })}
                />
            )}

            <SpacedGroup mt={1} spacing={2} flexDirection="column">
                <Box textAlign="center">
                    <Divider />
                    {!speakerConfig ? (
                        <Box py={2}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <SpeakerSelection
                            currentSpeaker={speakerConfig}
                            onSave={handleSpeakerSelect}
                            source="voiceovert2vTab"
                            onAudioPlay={logAnalyticsOnAudioPlay}
                        />
                    )}
                    <Divider />
                </Box>
                <Box>
                    <QgTranscript
                        text={audioNote?.text || ''}
                        markdown={transcriptText}
                        type="textToSpeech"
                        speakerConfig={speakerConfig}
                        language={language?.label || ''}
                        onChange={setTranscriptText}
                    />
                </Box>
            </SpacedGroup>

            <Box
                mt={buttonOnBottom ? 0 : 6}
                px={buttonOnBottom ? 2 : 0}
                position={buttonOnBottom ? 'absolute' : 'initial'}
                bottom={16}
                left={0}
                width="100%"
                textAlign="center"
            >
                <Box mx={-2} mb={2}>
                    <Divider />
                </Box>

                {speakerConfig && (
                    <Button
                        variant="contained"
                        size="large"
                        data-test="t2s-add-voiceover-button"
                        data-intercom="editor-add-voiceover-t2s"
                        disabled={!transcriptText?.length}
                        fullWidth
                        onClick={handleAddVoiceover}
                    >
                        ADD VOICEOVER
                    </Button>
                )}
            </Box>
        </Box>
    )
}
