import { useCallback, useMemo } from 'react'

import { Box, styled } from '@mui/material'

import { Icon, Tooltip } from '@guidde/design-system'
import { faMerge } from '@fortawesome/pro-regular-svg-icons/faMerge'

import { type TimelineChunksType, type TimelineVideoChunkType } from '../types'
import { checkIsVideoChunk } from '../splitAndTrimUtils'

import { useBoolean } from 'hooks'
import { logToAnalytics } from 'modules'

export const GAP_WIDTH = 4

const MergeButton = styled(Box)(({ theme }) => ({
    width: 24,
    height: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    transform: `translate(calc(-50% + ${GAP_WIDTH / 2}px), 50%)`,
    borderRadius: '4px',
    background: theme.palette.indigo[600],
    cursor: 'pointer'
}))

type Props = {
    chunkIndex: number
    chunks: TimelineChunksType
    setChunks: (value: TimelineChunksType) => void
}

export const TimelineConnector = ({ chunkIndex, chunks, setChunks }: Props) => {
    const hover = useBoolean()

    const isMergeAvailable = useMemo(() => {
        const currentChunk = chunks[chunkIndex] as TimelineVideoChunkType
        const nextChunk = chunks[chunkIndex + 1] as TimelineVideoChunkType

        return checkIsVideoChunk(currentChunk) && checkIsVideoChunk(nextChunk)
    }, [chunkIndex, chunks])

    const mergeChunks = useCallback(() => {
        const currentChunk = chunks[chunkIndex] as TimelineVideoChunkType
        const nextChunk = chunks[chunkIndex + 1] as TimelineVideoChunkType

        if (!currentChunk || !nextChunk) return

        const mergedChunk = {
            ...currentChunk,
            end: nextChunk.end
        }

        const newChunks = [
            ...chunks.slice(0, chunkIndex),
            mergedChunk,
            ...chunks.slice(chunkIndex + 2)
        ]

        setChunks(newChunks)
        logToAnalytics('editVideoStep_mergeBtn_clicked', { mergeTimeStamp: currentChunk.end })
    }, [chunkIndex, chunks, setChunks])

    return (
        <Box
            width={GAP_WIDTH}
            position="relative"
            sx={theme => ({
                cursor: 'default',
                zIndex: 5,
                '& div': { background: theme.palette.indigo[600] + '!important' },
                '& *': { color: 'white' }
            })}
            onMouseEnter={() => (isMergeAvailable ? hover.setTrue() : {})}
            onMouseLeave={hover.setFalse}
            onClick={e => {
                // prevent time change on click
                e.stopPropagation()
            }}
        >
            {hover.isTrue && (
                <Tooltip title="Merge">
                    <Box
                        sx={{
                            '& > *': {
                                position: 'absolute',
                                transform: `translate(calc(-50% + ${GAP_WIDTH / 2}px), 50%)`
                            }
                        }}
                    >
                        <MergeButton onClick={mergeChunks}>
                            <Icon icon={faMerge} color="red" />
                        </MergeButton>
                    </Box>
                </Tooltip>
            )}
        </Box>
    )
}
