import { useCallback, useEffect, useMemo, useState } from 'react'

import { Stack } from '@mui/material'

import { minMaxValue } from '../../TrimDialog/TrimSection'
import {
    TimelineChunkVariant,
    type TimelineChunksType,
    type TimelineVideoChunkType,
    type TrimmedChunk
} from '../types'
import { getChunkDuration } from '../splitAndTrimUtils'
import { logToAnalytics } from 'modules'

export const minChunkDuration = 1

type TrimSide = 'left' | 'right'

type Props = {
    endPoint: number
    duration: number
    timelineContainerElement: HTMLDivElement | null
    chunks: TimelineChunksType
    setChunks: (value: TimelineChunksType) => void
    chunkIndex: number
    trimmedChunk: Exclude<TrimmedChunk, null>
    setTrimmedChunk: (value: TrimmedChunk) => void
    trimSide: TrimSide | null
    setTrimSide: (value: TrimSide | null) => void
}

export const TimelineChunkTrim = ({
    endPoint,
    duration,
    timelineContainerElement,
    chunks,
    chunkIndex,
    setChunks,
    trimmedChunk,
    setTrimmedChunk,
    trimSide,
    setTrimSide
}: Props) => {
    const chunk = chunks[chunkIndex] as TimelineVideoChunkType

    const chunkLimitations = useMemo(() => {
        const videoChunks = chunks.filter(chunk => chunk.type === TimelineChunkVariant.video)

        const previousChunk = videoChunks[chunkIndex - 1] as undefined | TimelineVideoChunkType
        const nextChunk = videoChunks[chunkIndex + 1] as undefined | TimelineVideoChunkType

        return {
            start: previousChunk?.end || 0,
            end: nextChunk?.start || duration
        }
    }, [chunkIndex, chunks, duration])

    const chunkPositionOnTimeline = useMemo(() => {
        const chunksBefore = chunks.slice(0, chunkIndex)

        return chunksBefore.reduce((acc, chunk) => {
            const chunkDuration = getChunkDuration(chunk)
            return chunkDuration + acc
        }, 0)
    }, [chunkIndex, chunks])

    const isTrimming = Boolean(trimSide)
    const [expandTime, setExpandTime] = useState(0)
    const isExpanding = Boolean(expandTime)

    const chunkDurationBeforeTrim = getChunkDuration(chunk)

    const trimStart = useCallback(
        (possibleStartPoint: number) => {
            const startPoint = minMaxValue(
                possibleStartPoint,
                chunkLimitations.start,
                chunk.end - minChunkDuration
            )
            setTrimmedChunk({ start: startPoint, end: chunk.end })
        },
        [chunk, chunkLimitations.start, setTrimmedChunk]
    )

    useEffect(() => {
        const trimmedChunkStart = trimmedChunk.start

        if (isTrimming && isExpanding) {
            const potentialTime = trimmedChunkStart - expandTime

            const isExpandedToLimit =
                potentialTime < chunkLimitations.start &&
                trimmedChunkStart <= chunkLimitations.start
            if (isExpandedToLimit) return

            const isReducedExpanded =
                potentialTime > chunk.start && trimmedChunkStart >= chunk.start
            if (isReducedExpanded) return setExpandTime(0)

            trimStart(Math.min(trimmedChunkStart - expandTime, chunk.start))
        }
    }, [
        chunk,
        chunkLimitations.start,
        expandTime,
        isExpanding,
        isTrimming,
        trimStart,
        trimmedChunk.start
    ])

    useEffect(() => {
        if (!isTrimming || !timelineContainerElement) return

        const onMouseMove = (event: MouseEvent) => {
            const containerRect = timelineContainerElement.getBoundingClientRect()
            const mouseX = event.clientX
            const positionPercentage = (mouseX - containerRect.left) / containerRect.width

            const limitedToBordersPositionPercentage = Math.min(positionPercentage, 1)

            const positionTime = limitedToBordersPositionPercentage * endPoint

            if (trimSide === 'left') {
                const timeDifference = chunkPositionOnTimeline - positionTime

                const isExpandToLeftSide = positionTime < chunkPositionOnTimeline
                const isTrimExpanded = trimmedChunk.start < chunk.start

                if (isExpandToLeftSide || isTrimExpanded) {
                    setExpandTime(timeDifference * 0.3)
                    return
                }

                setExpandTime(0)
                trimStart(chunk.start - timeDifference)
            }

            if (trimSide === 'right') {
                const timeDifference =
                    positionTime - (chunkPositionOnTimeline + chunkDurationBeforeTrim)
                const endPoint = minMaxValue(
                    chunk.end + timeDifference,
                    chunk.start + minChunkDuration,
                    chunkLimitations.end
                )

                setTrimmedChunk({ start: chunk.start, end: endPoint })
            }
        }

        const onMouseUp = () => {
            setTrimSide(null)
            setExpandTime(0)
            document.body.style.cursor = 'auto'

            const updatedChunks: TimelineChunksType = [
                ...chunks.slice(0, chunkIndex),
                {
                    id: chunk.id,
                    type: chunk.type,
                    start: trimmedChunk.start,
                    end: trimmedChunk.end
                },
                ...chunks.slice(chunkIndex + 1, chunks.length)
            ]

            setChunks(updatedChunks)
            logToAnalytics('editVideoStep_trimBtn_clicked', {
                start: trimmedChunk.start,
                end: trimmedChunk.end
            })
        }

        document.addEventListener('mousemove', onMouseMove)
        document.addEventListener('mouseup', onMouseUp)

        return () => {
            document.removeEventListener('mousemove', onMouseMove)
            document.removeEventListener('mouseup', onMouseUp)
        }
    }, [
        chunk,
        chunkDurationBeforeTrim,
        chunkIndex,
        chunkLimitations,
        chunkPositionOnTimeline,
        chunks,
        endPoint,
        isTrimming,
        setChunks,
        timelineContainerElement,
        trimSide,
        trimStart,
        trimmedChunk,
        setTrimmedChunk,
        setTrimSide
    ])

    return (
        <>
            <TrimmedArea
                side="left"
                chunkDurationBeforeTrim={chunkDurationBeforeTrim}
                trimmedDuration={trimmedChunk.start - chunk.start}
            />

            <TrimSlider
                isTrimming={trimSide === 'left'}
                side="left"
                onMouseDown={() => setTrimSide('left')}
                timePoint={trimmedChunk.start - chunk.start}
                chunkDurationBeforeTrim={chunkDurationBeforeTrim}
            />

            <TrimSlider
                isTrimming={trimSide === 'right'}
                side="right"
                onMouseDown={() => setTrimSide('right')}
                timePoint={trimmedChunk.end - chunk.start}
                chunkDurationBeforeTrim={chunkDurationBeforeTrim}
            />

            <TrimmedArea
                side="right"
                chunkDurationBeforeTrim={chunkDurationBeforeTrim}
                trimmedDuration={chunk.end - trimmedChunk.end}
            />
        </>
    )
}

const TrimSlider = ({
    isTrimming,
    side,
    onMouseDown,
    chunkDurationBeforeTrim,
    timePoint
}: {
    isTrimming: boolean
    side: TrimSide
    onMouseDown: () => void
    chunkDurationBeforeTrim: number
    timePoint: number
}) => {
    const percentagePosition = (timePoint / chunkDurationBeforeTrim) * 100

    const rightPositionPercentage = 100 - percentagePosition

    return (
        <Stack
            sx={theme => ({
                background: theme.palette.primary[600],
                width: 6,
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'ew-resize',
                opacity: isTrimming ? '1' : '0',
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: side === 'left' ? `max(0%, calc(${percentagePosition}% - 3px))` : undefined,
                right:
                    side === 'right'
                        ? `max(0%, calc(${rightPositionPercentage}% - 3px))`
                        : undefined,
                zIndex: 2
            })}
            onMouseDown={() => {
                onMouseDown()
                document.body.style.cursor = 'ew-resize'
            }}
        >
            <Stack
                sx={theme => ({
                    background: theme.palette.common.white,
                    width: 2,
                    height: 20,
                    borderRadius: '28px'
                })}
            />
        </Stack>
    )
}

const TrimmedArea = ({
    side,
    trimmedDuration,
    chunkDurationBeforeTrim
}: {
    side: TrimSide
    trimmedDuration: number
    chunkDurationBeforeTrim: number
}) => {
    if (trimmedDuration <= 0) return null

    const widthPercentage = (trimmedDuration / chunkDurationBeforeTrim) * 100

    return (
        <Stack
            sx={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: side === 'left' ? 0 : undefined,
                right: side === 'right' ? 0 : undefined,
                background: 'rgba(0, 0, 0, 0.6)',
                width: `${widthPercentage}%`,
                zIndex: 1,
                cursor: 'ew-resize'
            }}
        />
    )
}
