import { useEffect, type MutableRefObject } from 'react'

import { CircularProgress, Stack } from '@mui/material'

import { useBoolean, type UseBooleanType } from 'hooks'

import { type ActiveChunkDetailsType } from '../useMapTimelineToVideoTime'
import { checkIsSnapshotChunk, checkIsVideoChunk } from '../splitAndTrimUtils'
import { type TimelineChunksType, type TimelineVideoChunkType } from '../types'

type Props = {
    videoUrl: string
    videoPlayerRef: MutableRefObject<HTMLVideoElement | null>
    playing: UseBooleanType
    setPlayerTime: (value: number) => void
    isActiveVideoChunk: boolean
    activeChunk: ActiveChunkDetailsType
    chunks: TimelineChunksType
}

export const VideoPlayer = ({
    videoPlayerRef,
    videoUrl,
    playing,
    setPlayerTime,
    isActiveVideoChunk,
    activeChunk,
    chunks
}: Props) => {
    const videoPlayerElement = videoPlayerRef.current

    const videoSeeking = useBoolean()

    useEffect(() => {
        if (!isActiveVideoChunk || !videoPlayerElement) return

        if (playing.isTrue) {
            videoPlayerElement.play()
            return
        }

        videoPlayerElement.pause()
    }, [isActiveVideoChunk, playing.isTrue, videoPlayerElement])

    return (
        <Stack sx={{ position: 'relative' }}>
            {videoSeeking.isTrue && (
                <Stack
                    sx={{
                        position: 'absolute',
                        inset: 0,
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <CircularProgress />
                </Stack>
            )}
            <video
                src={videoUrl}
                ref={videoPlayerRef}
                style={{ height: 330, background: 'black' }}
                crossOrigin="anonymous"
                onSeeked={videoSeeking.setFalse}
                onSeeking={videoSeeking.setTrue}
                onLoadedData={e => {
                    const target = e.currentTarget as HTMLVideoElement
                    target.currentTime =
                        (activeChunk.originalChunk as TimelineVideoChunkType)?.start || 0
                }}
                onTimeUpdate={event => {
                    const target = event.currentTarget
                    if (target.paused || target.ended) return

                    const { chunkPositionOnTimeline, originalChunk } = activeChunk
                    if (checkIsSnapshotChunk(originalChunk)) return target.pause()

                    const targetTime = target.currentTime

                    if (targetTime < originalChunk.start) target.currentTime = originalChunk.start

                    const timeDifferenceFromChunkStart = targetTime - originalChunk.start

                    setPlayerTime(
                        chunkPositionOnTimeline.start + Math.max(0, timeDifferenceFromChunkStart)
                    )
                }}
                onPause={event => {
                    const targetTime = event.currentTarget.currentTime

                    const originalChunk = activeChunk.originalChunk
                    if (checkIsSnapshotChunk(originalChunk)) return

                    const nextChunk = chunks[activeChunk.activeChunkIndex + 1]
                    if (targetTime >= originalChunk.end && checkIsSnapshotChunk(nextChunk)) return

                    playing.setFalse()
                }}
                onPlay={event => {
                    playing.setTrue()

                    const target = event.currentTarget

                    const lastVideoChunk = chunks.findLast(checkIsVideoChunk)

                    if (lastVideoChunk && target.currentTime >= lastVideoChunk.end) {
                        const firstVideoChunk = chunks.find(checkIsVideoChunk)
                        target.currentTime = firstVideoChunk?.start ?? 0
                    }
                }}
            />
        </Stack>
    )
}
